import React from 'react';
import configure_attack from '../assets/images/configure_attack.png';
import configure_attack2 from '../assets/images/configure_attack2.png';
import modeldatacatalog from '../assets/images/modeldatacatalog.png';
import pdfdownload from '../assets/images/pdfdownload.png';
import user_login from '../assets/images/user_login.png';
import selectdatacatalog from '../assets/images/selectdatacatalog.png';
import modelscanbutton from '../assets/images/modelscanbutton.png';
import modelscanresults from '../assets/images/modelscanresults.png';
import modelperformance from '../assets/images/modelperformance.png';

// TODO: Figure out why there's a gap between content and window edge??
// TODO: Add a blurb about the scanning, describe the button
// TODO: Add some 
const DocsPage = () => {

        const pageContainerStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: '20px',
        width: '95%',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f9f9f9',
    }

    const contentStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
        padding: '1em',
        // paddingLeft: '2em',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#f9f9f9',
        gridColumn: '2 / 3',
    };

    const sidebarStyle = {
        gridColumn: '1 / 2',
        padding: '2em',
        position: 'sticky',
        top: '0',
        left: '0',
        height: '100vh',
        overflow: 'auto',
        backgroundColor: '#f9f9f9',
    }

    const sectionStyle = {
        gridColumn: '1 / -1', // span both columns
        marginBottom: '1em',
        fontSize: '2em',
        fontWeight: 'bold',
        color: '#47854e',
    };

    const bodyTextStyle = {
        marginBottom: '1em',
        fontSize: '1em',
        paddingLeft: '2em',
        lineHeight: '1.6', 
        gridColumn: '1 / 2',
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        margin: '1em',
        gridColumn: '2 / 3',
    };

    const contentsHeaderStyle = {
        fontSize: '1.5em',
        color: '#475569',
    };

    const subSectionStyle = {
        color: '#475569',
        fontSize: '1.50em', 
        fontWeight: 'bold',
        marginBottom: '0.5em',
        gridColumn: '1 / -1',
    };

    const subSubSectionStyle = {
        color: '#475569', // Apply the color #fcbe16 to sub-sub-section headers
        fontSize: '1.25em', // Slightly smaller than sub-section headers
        fontWeight: 'bold',
        marginBottom: '0.5em', // Adjust as needed
        // paddingLeft: '20px', // Indent sub-sub-section headers
        gridColumn: '1 / -1',
    };

    const orderedListStyle = {
        listStyleType: 'decimal',
        paddingLeft: '20px',
    }

    const unorderedListStyle = {
        listStyleType: 'disc',
        paddingLeft: '20px', // Adjust padding as needed
    };
    
  return (


    <div style={pageContainerStyle}>
        <div style={sidebarStyle}>
            <h2 style={contentsHeaderStyle}>Contents</h2>
                <ul style={unorderedListStyle}>
                    <li><a href="#overview">Overview</a></li>
                    <li><a href="#login">Login to Tumeryk</a></li>
                    <li><a href="#modeldatacatalog">Adding the Model and Data Catalog</a></li>
                    <li><a href="#configureattack">Configuring the Attack</a></li>
                    <li><a href="#invokeattack">Invoking the Attack</a></li>
                    <li><a href="#pdfdownload">Downloading the Tumeryk Report</a></li>
                    <li><a href="#dashboard">Using the Tumeryk Dashboard to understand model Performance</a></li>
                    <li><a href="#scanning">Scanning the Model for Adversarial Threats</a></li>
                    <li><a href="#background">Background</a></li>
                    <li><a href="#acknowledgments">Acknowledgments</a></li>
                </ul>
        </div>

        <div style={contentStyle}>
            <h2 id="overview"style={sectionStyle}>Overview</h2>
            <div style={{...bodyTextStyle, gridColumn: '1 / 3'}}>
                  <p>In this section, we provide an overview of the integration of Tumeryk AttackGuard into the development process 
                    and its role in assessing AI/ML models. </p>
            </div>
            <h3 style={subSectionStyle}> Assessment for Adversarial Threats</h3>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>Tumeryk AttackGuard assesses models for adversarial threats and provides insights into potential vulnerabilities.
                        By executing from a Catalog of attacks matched with the capability of the target models, Accuracy and Metrics for
                        the model are charted for both expected data as well as adversarial synthetic data generated by the attacks. This
                        adversarial data is made available for future retraining of the model for robustness. It is also used in the model
                            for the protection of the real-time models inference.</p>    
                </div>

            <h3 style={subSectionStyle}> Explainability and Fairness Assessment</h3>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>Model fairness is a paramount concern in machine learning, ensuring that models do not discriminate against particular
                        groups or individuals. The data scientist was acutely aware of the importance of model fairness and developed scripts
                        to evaluate and enhance it. Key concepts related to model fairness include:</p>
                </div>

            <h4 style={subSubSectionStyle}> Sensitive Features</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>These are attributes within the dataset that, if mishandled, can result in discriminatory model behavior. Sensitive
                        features may include gender, race, or age. Ensuring fairness means preventing models from making biased decisions 
                        based on these features.</p>
                </div>
            <h4 style={subSubSectionStyle}> Fairness Evaluation</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>The data scientist's scripts rigorously assessed model fairness by scrutinizing disparities in prediction outcomes
                        across different sensitive groups. They measured and mitigated bias to achieve fairer models.
                    </p>
                </div>

            <h3 style={subSectionStyle}> Model Explainability</h3>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                  <p>Model explainability revolves around the capacity to understand and interpret the decisions made by machine learning models.
                     Recognizing the significance of model explainability, the data scientist developed scripts that provided insights into model
                      behavior. This entailed generating plots and visualizations to render model decisions more transparent to stakeholders.</p>
                </div>

            <h3 style={subSectionStyle}>Retraining Models with Adversarial Data</h3>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                  <p>The original model when shown to be susceptible to adversarial attacks – as noted by accuracy of the adversarial data being 
                    high, requires injection of the adversarial data in the model training data. Caution must be used as too much adversarial data
                     will result in lowering of model accuracy and too little will not provide sufficient robustness. If you wish to access the 
                     adversarial data you will need to get a license for the product. Please contact info@tumeryk.com.</p>
                </div>

            <h2 id="login" style={sectionStyle}>
                Login to Tumeryk
            </h2>
                <div style={bodyTextStyle}>
                    <ol style={orderedListStyle}>
                        <li>Use the Username as provided by Tumeryk.</li>
                        <li>Use the Password as provided by Tumeryk.</li>
                        <li>Click on SIGN IN.</li>
                    </ol>
                </div>
                <img src={user_login} alt="User Login" style={imageStyle} />

            <h2 id="modeldatacatalog" style={sectionStyle}>
                Adding the Model and Data Catalog
            </h2>
                <div style={bodyTextStyle}>
                    <ol style={orderedListStyle}>
                        <li>On the AI Model Catalog page, click on "Add Model".</li>
                        <li>In the pop-up, choose a name for the Model and the Data Catalog.</li>
                        <li>Add a description for a the model and data.</li>
                        <li>Select and upload the respective files for.</li>
                        <ul style={unorderedListStyle}>
                            <li>(a) Model: The model file (accepts: .pkl .bin).</li>
                            <li>(b) x_test: The X test split of your dataset.</li>
                            <li>(c) y_test: The Y test split of your dataset.</li>
                            <li>(d) x_train: The X train split of your dataset.</li>
                            <li>(e) y_train: The Y train split of your dataset.</li>
                            <li>(f) pred: The predictions of your model.</li>
                        </ul>
                  </ol>
                </div>
                <img src={modeldatacatalog} alt="Model Data Catalog" style={imageStyle} />
            
            <h2 id="configureattack" style={sectionStyle}>
                Configuring the Attack
            </h2>
                <div style={bodyTextStyle}>
                    <ul style={unorderedListStyle}>
                        <li>(I) After logging into the Tumeryk profile go to the tab "Attacks".</li>
                        <li>(II) Click on the option "Add Attacks" that will open the following window.</li>
                        <li>(III) The available list comprises the Attacks that have already been configured by the user.</li>
                    </ul>
                </div>
                <img src={configure_attack} alt="Configure Attack" style={imageStyle} />
                <div style={bodyTextStyle}>
                    <ol style={orderedListStyle}>
                        <li> (a) In "Attack Name", enter a suitable name or description for the attack.</li>
                        <li> (b) Using the "Volume" option the user can define the number of records out of the dataset, using the portion which the user wants to simulate the attack on the model.</li>
                        <li> (c) "Choose Model" drop down menu presents the user with the models that they have added in the database. Using this, the users can select the model for which they want to configure the attack.</li>
                        <li> (d) Users can select the attack that they want to simulate on the model using "Choose Attack" drop down menu.</li>
                        <li> (e) Click on the "Submit" option to generate the configured attack specific to a Model that was selected during configuration. The attack will be available in the list for use.</li>
                    </ol>
                </div>  
                 <img src={configure_attack2} alt="Configure Attack 2" style={imageStyle} />

            <h2 id="invokeattack" style={sectionStyle}>
                Invoking the Attack
            </h2>
                <div style={bodyTextStyle}>
                    <ul style={unorderedListStyle}>
                        <li>Users will have to select the attack configuration that they want to run from the list available in "Attack" tab. </li>
                        <li>User will have to click on the "Invoke" option which will open the invoke window.</li>
                        <li>(a) Select the data catalog from the list of available data catalogs using the dropdown menu.</li>
                        <li>(b) Click on the "Invoke" button to invoke the attack</li>
                    </ul>
                </div>
                <img src={selectdatacatalog} alt="Data Catalog Window" style={imageStyle} />

            <h2 id="pdfdownload" style={sectionStyle}>
                Downloading the Tumeryk Report
            </h2>
                <div style={bodyTextStyle}>
                    <ul style={unorderedListStyle}>
                        <li>Navigate to the AI Model Catalog</li>
                        <li>In the displayed table, at the far right of the table, there is a column labeled "PDF".</li>
                        <li>Click on the PDF icon to download the PDF report for the model.</li>
                    </ul>
                </div>
                <img src={pdfdownload} alt="PDF Download" style={imageStyle} />
            
            <h2 id="dashboard" style={sectionStyle}>
                Using the Tumeryk Dashboard to understand model Performance
            </h2>
                <div style={bodyTextStyle}>
                    <ul style={unorderedListStyle}>
                        <li>Navigate to the Dashboard.</li>
                        <li>Select the model for which you wish to see performance metrics of.</li>
                        <li>The charts are generated on the page for your interpretation.</li>
                    </ul>
                </div>
                <img src={modelperformance} alt="Model Performance" style={imageStyle} />

            <h2 id="scanning" style={sectionStyle}>
                  Scanning the Model for Adversarial Threats
            </h2>
                <div style={bodyTextStyle}>
                  <p>When the user invokes an attack on a model, our system will scan the files for any adversarial attacks
                     introduced by directly modifying the binary. Users can review the results of the modelscan in the “Dashboard”
                      page, where the the model performance charts are located. Users can click on the “Scan Results” button to view
                       the integrity of their model.</p>
                </div>
                <img src={modelscanbutton} alt="Model Scan Button" style={imageStyle} />
                <img src={modelscanresults} alt="Model Scan Results" style={imageStyle} />

            <h2 id="background" style={sectionStyle}>
                Background  
            </h2>
                <div style={bodyTextStyle}>
                    <p>Below are some concepts used in model assessment.</p>
                </div>
                <h4 style={subSubSectionStyle}>Adversarial example/image</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>Examples/images that are intentionally manipulated to cause incorrect model prediction. These examples (or a series of such examples) are provided as input to the model.</p>
                </div>
                <h4 style={subSubSectionStyle}>Adversarial Perturbation</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p> Perturbation are the component of an adversarial example/imeage that causes incorrect prediction. Commonly, it is a low magnitude additive noise-like signal.</p>
                </div>
                <h4 style={subSubSectionStyle}> Adversary</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>It is the agent (i.e. the attacker) creating an adversarial example. Alternatively, the adversarial signal/perturbation is also referred to as the adversary, albeit much less often.</p>
                </div>
                <h4 style={subSubSectionStyle}>Defense/Adversarial Defense</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>It is a broader term used for any mechanism of introducing robustness in a model, or external/internal mechanisms to detect adversarial signals,
                        or image processing to negate adversarial effects of input manipulations. Target image: It is the clean example/image being manipulated by the adversary.</p>
                </div>
                <h4 style={subSubSectionStyle}>Target Label</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>It is the (desired) incorrect label of the adversarial example. The term is more relevant for classification problems.</p>
                </div>
                
                <h4 style={subSubSectionStyle}> Types of Adversarial Attacks</h4>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>There are different bases on which adversarial attacks can be classified.</p>
                </div>

                <h3 style={subSectionStyle}>Adversarial Capabilities</h3>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>It refers to the amount of information available to an adversary about the system. We explore the range of attacker capabilities by further dividing them into inference and training phases.</p>
                    </div>

                    <h4 style={subSubSectionStyle}>Training Phase Capabilities</h4>
                    <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>Most of the attacks in the training phase are accomplished by learning, influencing or corrupting the model by direct alteration of the dataset. The attack strategies are broadly classified 
                        into the following three categories based on the adversarial capabilities:</p>
                        <ul style={unorderedListStyle}>
                            <li>Data Injection: When adversary cannot access training data or learning algorithm but has ability to augment new data to 
                                the training st. He can corrup the target model by inserting adversarial samples into the training.
                            </li>
                            <li>Data Modification: The adversary does not have access to the learning algorithm but has full access to the training data.
                                They poison the training data by modifying the data before it is used for training the model.
                            </li>
                            <li>Logic Corruption: The adversary is able to meddle with the learning algorithm. Apparently, it becomes very difficult
                                to design counter strategies against these.
                            </li>
                        </ul>
                    </div>
                    <h4 style={subSubSectionStyle}>Testing Phase Capabilites</h4>
                    <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                        <p>Most of the attacks in the training phase are accomplished by learning, influencing or corrupting the model by direct alteration of the dataset.
                            The attack strategies are broadly classified into the following three categories based on the adversarial capabilities.
                        </p>
                        <ul style={unorderedListStyle}>
                            <li>White-box attack: In white-box attack on a machine learning model, an adversary has total knowledge about the model used
                                (e.g. type of neural network along with number of layers, information about the algorithm used in the training, parameters
                                of the fully trained model architecture). The adversary utilizes this information to analyze the feature space where the
                                model might be vulnerable, that is, for which the model has a high error rate. The access to internal model weights for a
                                white-box attack corresponds to a very strong adversarial attack.
                            </li>
                            <li>Black-box Attack: This type of attack assumes no knowledge about the model and uses information about the settings and prior
                                inputs to exploit the model. Black-box attacks are further subdivided into the three categories: Non-adaptive black-box attack,
                                adaptive black-box attack, and Strict black-box attack.
                            </li>
                        </ul>
                    </div>
                <h3 style={subSectionStyle}>Attack Surface</h3>
                    <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                    <p>Given a pipeline of steps, an attacker can choose to target a particular step (or surface) of the pipeline to achieve his/her goal.
                        The main attack scenarios identified by the attack surface are sketched as follows.
                    </p>
                    <ul style={unorderedListStyle}>
                        <li>Evasion Attack: Evasion attacks include taking advantage of a trained model's flaw. The adversaries try to feed the perturbed
                            data to the model in order to confuse the model. The perturbations are sometimes so small that it cannot be detect by statistical
                            analysis but it is enough to confuse the model.
                        </li>
                        <li>Poisoning Attacks: This type of attack, known as contamination of the training data, is carried out at training phase by injecting
                            skillfully crafted samples to poison the system in order to compromise the entire learning process.
                        </li>
                        <li>
                            Extraction Attack: Malicious users could query these black-box models and collect the output to make a dataset. Then the adversary
                            may choose a substitute model and train it on the data they collect to 'steal' the model that has the same function as the original one.
                            Since a model with high performance requires significant resource allocation, the attack may lead to huge losses for the providers.
                        </li>
                        <li>Inference Attack: Inference attacks take advantage of this property to discover or reconstruct the examples used to train the machine
                            learning model. This could have privacy ramifications for the people whose data records were used to train the model. In membership inference attacks,
                            the adversary does not necessarily need to have knowlege about the inner parameters of the target machine learning model. Instead, the attacker only
                            knows the model's algorithm and architecture (e.g. SVM, Neural Network, etc.) or the service used to create the model. 
                        </li>
                    </ul>
                </div>
                <h3 style={subSectionStyle}>Real-life Examples</h3>
                    <h4 style={subSubSectionStyle}>Fooling Image Recognition Systems (2014)</h4>
                        <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                            <p>In 2014, researchers demonstrated the vulnerability of deep neural networks (DNNs) used for image recognition. They created adversarial examples 
                                by adding imperceptible perturbations to images, causing the DNNs to misclassify objects. For example, they modified an image of a panda to be 
                                misclassified as a gibbon with high confidence.</p>
                        </div>
                    <h4 style={subSubSectionStyle}>Misleading Autonomous Vehicles (2017)</h4>
                        <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                            <p>In 2017, researchers at the University of Washington demonstrated adversarial attacks against object detection systems used in autonomous vehicles. 
                                By strategically placing stickers on a stop sign, they deceived the object detection algorithm into misclassifying it as a speed limit sign. This 
                                highlights the potential risks of adversarial attacks in real-world safety-critical applications.</p>
                        </div>
                    <h4 style={subSubSectionStyle}>Voice Recognition Attacks (2018)</h4>
                        <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                            <p>In 2018, researchers showed that speech recognition systems could be vulnerable to adversarial attacks. By imperceptibly modifying audio waveforms, 
                                they could trick voice assistants like Amazon Alexa or Google Assistant into recognizing a different command from what the user actually spoke. 
                                These attacks have implications for voice-controlled systems used in smart homes and other applications.</p>
                        </div>
                    <h4 style={subSubSectionStyle}>Evasion Attacks on Spam Filters (2019)</h4>
                        <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                            <p>Adversarial attacks are not limited to computer vision or speech recognition systems. In 2019, researchers demonstrated that spam email filters could
                                be evaded using carefully crafted messages. By manipulating the text or structure of an email, attackers could bypass the filters and make malicious 
                                emails appear legitimate, leading to potential phishing or malware attacks.</p>
                        </div>
                    <h4 style={subSubSectionStyle}>Adversarial Attacks on Medical Imaging (2020)</h4>
                        <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                            <p>Medical imaging systems, such as those used for diagnosing diseases, are not immune to adversarial attacks. In 2020, researchers demonstrated the 
                                vulnerability of deep learning models for chest X-ray interpretation. By modifying the pixel values in a chest X-ray image, they fooled the model 
                                into misdiagnosing pneumonia or COVID-19, highlighting the potential risks in healthcare applications.</p>
                        <br/>
                            <p>More examples can be found at <a href="https://atlas.mitre.org/studies">https://atlas.mitre.org/studies</a></p>
                        <br/>
                            <p>These incidents highlight the importance of developing robust and resilient machine learning models that are resistant to adversarial attacks. Ongoing 
                                research focuses on developing defense mechanisms to mitigate these vulnerabilities and enhance the security of machine learning systems.</p>
                        <br/>
                            <p>Adversarial attacks in the context of machine learning are still an active area of research, and while there have been several demonstrations of vulnerabilities 
                                and potential risks, the impact on financial losses in real-world scenarios is not well-documented.</p>
                        <br/>
                            <p>However, it's important to note that the potential for financial losses due to adversarial attacks exists, especially in domains where machine learning models play 
                            a crucial role. For example, if an autonomous vehicle's object detection system is deceived by adversarial attacks, it could lead to accidents, property damage, and 
                            potential legal liabilities for the involved parties, resulting in financial losses.</p>
                        <br/>
                          <p>Similarly, in industries such as finance, where machine learning models are utilized for fraud detection or algorithmic trading, successful adversarial attacks could
                             potentially lead to financial fraud or manipulation. However, it's important to conduct further research and stay updated on the latest developments to determine if 
                             any specific incidents have occurred since my knowledge cutoff.</p>
                        </div>
            <h2 id="acknowledgments" style={sectionStyle}>
                Acknowledgments
            </h2>
                <div style={{ ...bodyTextStyle, gridColumn: '1 / 3' }}>
                          <p>The product uses open source software for some of the capabilities. We acknowledge the contributions of these open source developers. Some of the key components are referenced here.</p>
                          <br/>
                          <p>Fairlearn: Assessing and Improving Fairness of AI Systems License: https://github.com/fairlearn/fairlearn?tab=MIT-1-ov-file#readme</p>
                          <br/>
                          <p>PIML An integrated Python toolbox for interpretable machine learning  License:https://github.com/SelfExplainML/PiML-Toolbox?tab=Apache-2.0-1-ov-file#readme</p>
                          <br/>
                          <p>ART Adversarial Robustness Toolbox v1.17, License: https://github.com/Trusted-AI/adversarial-robustness-toolbox?tab=MIT-1-ov-file#readme</p>
                          <br/>
                          <p> ModelScan License: https://github.com/protectai/modelscan?tab=Apache-2.0-1-ov-file#readme</p>
                </div>  
        </div>
    </div>
  );
};

export default DocsPage;
