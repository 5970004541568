import './App.css';

// Pages
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import ModelsPage from './pages/ModelsPage';
import ModelChartPage from './pages/ModelChartPage';
import AttacksPage from './pages/AttacksPage';
import DocsPage from './pages/DocsPage';

// Components
import HeaderComponent from './components/HeaderComponent';
import LoadingSpinnerComponent from './components/LoadingSpinnerComponent';

// Configs
import client from "./client";

// React
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react';

// Component for setting the title
import TitleComponent from './components/TitleComponent';

function App() {
  // 0: Checking login status
  // 1: Not logged in
  // 2: Logged in
  const [loggedIn,setLoggedIn] = useState(0); 
  useEffect(() => {
    client.get("/private")
    .then(response => (response.status === 200 ? setLoggedIn(2) : setLoggedIn(1)))
    .catch(error => {setLoggedIn(1)})
  }, []);
  
  return (
    <div>
      <TitleComponent loggedIn={loggedIn} /> {/* Use the Title component here */}
      {loggedIn === 2 ? 
      <div>
        <Router>
          <HeaderComponent />
          <Routes>
            <Route path='/' element={<LoginPage />} />
            <Route path='/models' element={<ModelsPage />} />
            <Route path='/model/:id' element={<ModelChartPage/>}></Route>
            <Route path='/attacks' element={<AttacksPage />} />
            <Route path='/docs' element={<DocsPage />} />
          </Routes>
        </Router>
      </div>
    : loggedIn === 1 ? 
    <div>
        <Router>
          <Routes>
            <Route path='/register' element={<RegistrationPage />} />
            <Route path='*' element={<LoginPage />} />
          </Routes>
        </Router>
    </div> : <LoadingSpinnerComponent size={500}/>}
  </div>
  ) 
}

export default App;
