import React from 'react';
import { Link } from 'react-router-dom';

function ButtonComponent({classNames, text, link_to}) {
    const classes = `h-12 w-48 my-auto text-center table font-bold ${classNames}`
    return (
      <Link className={classes} to={link_to}>
        <div className='table-cell align-middle'>
            <p>{text}</p>
        </div>
      </Link>
    );
  }
  
  export default ButtonComponent;