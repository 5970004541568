import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectTmrykIDComponent({label, content, styling, onChange}) {
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);

    // Call the provided onChange callback with the selected value
    if (onChange) {
      onChange(selectedValue);
    }
  };

  return (
    <div className={styling}>
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label="Value"
          onChange={handleChange}
        >
          {content.map((item) => {
            return <MenuItem value={item.tmryk_id}>{`[${item.tmryk_id}] ${item.attack_name}`}</MenuItem>
          })}
        </Select>
      </FormControl>
    </Box>
    </div>
  );
}