import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import client from "../client";
import PlusIcon from '../assets/PlusIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '5px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalComponent({button_styling}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const [files, setFiles] = useState({
    model: null,
    x_test: null,
    x_train: null,
    y_test: null,
    y_train: null,
    pred: null,
  });

  const handleFileChange = (e, label) => {
    const selectedFile = e.target.files[0];
    setFiles((prevFiles) => ({
      ...prevFiles,
      [label]: selectedFile,
    }));
  };

  const uploadFiles = async (model_id, datacatalog_id) => {
    try {
      const entries = Object.entries(files);
  
      for (const [label, file] of entries) {
        if (!file) {
          console.log("Not uploading file for label:", label, "because no file was selected");
          continue; // Skip if no file is selected for the label
        }
  
        const formData = new FormData();
        formData.append('file', file);
  
        await new Promise((resolve) => setTimeout(resolve, 100)); // Introduce 100ms delay
  
        await client.post(
          `/upload?param_name=${label}&model_id=${model_id}&datacatalog_id=${datacatalog_id}`, 
          formData, 
          {headers: {'Content-Type': 'multipart/form-data'}}
        );
      }
  
      console.log('File uploads successful');
    } catch (error) {
      console.error('File uploads failed:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const modelName = e.target.elements.modelName.value;
    const dataCatalogName = e.target.elements.dataCatalogName.value;
    const modelDesc = e.target.elements.modelDesc.value;
    
    client.get("/tenants")
    .then(response => { // Get tenant ID
      return response.data[0].id
    }) 
    .then((response_tenant_id) => { // 1) Create Model
      console.log(response_tenant_id)
      const createModelReqBody = {
        "id": -1, // unused but required by API
        "name": modelName,
        "model_type": "MDL.001",
        "date_posted": new Date().toISOString(),
        "tenant_id": response_tenant_id,
        "description": modelDesc
      }
      return client.post("/model", createModelReqBody).then((response) => [response_tenant_id, response.data.id])
    })
    .then((id_array) => { // 2) Create Data Catalog
      console.log("ID of newly created model used in new datacatalog:", id_array[1])
      const dataCatalogReqBody = {
        "id": -1, // unused but required by API
        "dataset_type": "training",
        "description": "description of the catalog",
        "is_generated_data": true,
        "tenant_id": id_array[0],
        "model_id": id_array[1],
        "name": dataCatalogName,
        "dataset_url": "https://www.example.com/dataset/data.csv",
        "model_type": "Classification",
        "parameters": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "model_name": "2/Model Name/MDL.001/training/dt_model-dac001e0-a852-4fa7-8d98-4c7c3e1d2bf1.joblib"
        }
      }
      return client.post("/datacatalog", dataCatalogReqBody).then((response) => [id_array[1], response.data.id])
    })
    .then((ids) => {return uploadFiles(ids[0], ids[1]);})
    .then(() => window.location.reload())
    .catch((error) => console.log(error))

    console.log('Model Name:', modelName);
    console.log('Description:', modelDesc);
  };

  return (
    <div>
      <a onClick={handleOpen} className='cursor-pointer'>
      <PlusIcon 
        styling = {`${button_styling} ml-12 mb-4 border-4 w-32 rounded-md inline-flex`}
        text="Add Model" />
      </a>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='flex justify-center mb-4'>
            <h2 id="modal-modal-title" className='text-2xl '>Add Model and Serialized Data</h2>
          </div>
          <form onSubmit={handleSubmit}>
              <TextField id="outlined-basic" style={{ width: '12rem', marginRight: '1rem', marginBottom: '1rem' }}  label="Model Name" name="modelName" variant="outlined" />
              <TextField id="outlined-basic" style={{ width: '19rem', marginBottom: '1rem' }}  label="DataCatalog Name" name="dataCatalogName" variant="outlined" />
              <TextField id="outlined-basic" style={{ width: '32rem', marginBottom: '1.5rem'}} label="Model Description" name="modelDesc" variant="outlined" />
            {Object.entries(files).map(([label, file]) => (
              <div key={label} className='mb-2'>
                <input className="" type="file" onChange={(e) => handleFileChange(e, label)} />
                <label>{label}</label>
              </div>
            ))}
            <div className="flex justify-center">
              <Button type="submit" variant="contained" style={{ backgroundColor: '#e9e9ed', color: '#000', marginRight: '2rem'}}>Submit</Button>
              <Button onClick={handleClose} variant="contained" style={{ backgroundColor: '#e9e9ed', color: '#000' }}>Cancel</Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}