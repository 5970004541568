import React, { useState } from 'react';
import LoadingSpinnerComponent from './LoadingSpinnerComponent';
import 'react-toastify/dist/ReactToastify.css';
import AddAttackModalComponent from './AddAttackModalComponent';
import theme from '../theme';

function AttacksTableComponent({data, selectedAttack, setSelectedAttack, refreshAttacks}) {
    const styling = `p-2 border-2 text-center`
    
    const handleRowClick = (item) => {
      // Check if the item is already selected
      const isSelected = selectedAttack.includes(item);

      // Update the selected items state based on the current selection
      if (isSelected) {
        setSelectedAttack((prevSelected) => prevSelected.filter((existingItem) => existingItem !== item));
      } else {
        setSelectedAttack((prevSelected) => [item]);
      }
    };

    data.sort((a, b) => (a.id > b.id) ? 1 : -1)
    return ( data.length > 0 ?
      <div>
        <AddAttackModalComponent refreshAttacks={refreshAttacks}/>
        <table className={`table-auto ml-12 ${theme.primary_text_color}`}>
        
          <thead>
            <tr>
              <th className={styling}>Attack ID</th>
              <th className={styling}>TMRYK ID</th>
              <th className={styling}>Mitre ID</th>
              <th className={styling}>Model ID</th>
              <th className={styling}>Model Name</th>
              <th className={styling}>Volume</th>
            </tr>
          </thead>

          <tbody>
          {data.map((item, index) => (
            // <tr key={index} className={index % 2 === 0 ? 'bg-green-50' : 'bg-gray-50'}></tr>
            <tr 
              key={index} 
              // className={index % 2 === 0 ? 'bg-yellow-400' : 'bg-white'}
              className={"cursor-pointer  " + (selectedAttack.includes(item) ? 'bg-green-700 !text-slate-50' : index % 2 === 0 ? 'bg-green-50' : 'bg-gray-50')}
              onClick={() => handleRowClick(item)}
            >
              <td className={styling}>{item.id}</td>
              <td className={styling}>{item.tmryk_id}</td>
              <td className={styling}>{item.mitre_id}</td>
              <td className={styling}>{item.model_id}</td>
              {/* <td className={styling}>{item.model_name}</td>*/}
              {/* USE DUMMY MODEL NAME UNTIL MODEL NAME IS RETURNED IN API RESP */}
              {/* /models/:id RETURNS 500 AT THE MOMENT */}
              <td className={styling}>{`model-${item.model_id}`}</td>
              <td className={styling}>{item.attack_volume}</td>
            </tr>
          ))}
          </tbody>

        </table> 
        
      </div>
      : <LoadingSpinnerComponent size={400}/>
    );
  }
  
  export default AttacksTableComponent;