import React from 'react';

function InvokeComponent({classNames, text, action}) {
    const classes = `h-12 w-48 my-auto ml-12 text-center table font-bold ${classNames}`
    return (
      <div className={classes} onClick={action}>
        <div className='table-cell align-middle'>
            <p>{text}</p>
        </div>
      </div>
    );
  }
  
  export default InvokeComponent;