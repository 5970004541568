import React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingSpinnerComponent({ size }) {
    return (
        <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row" className='flex w-full h-full justify-center items-center pt-32'>
            <CircularProgress color="inherit" size={size}/>
        </Stack>
    );
  }
  
  export default LoadingSpinnerComponent;