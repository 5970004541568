import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import BasicSelect from '../components/BasicSelectComponent';
import SelectTmrykIDComponent from './SelectTmrykIDComponent';

import client from "../client";
import PlusIcon from '../assets/PlusIcon';
import { ToastContainer, toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddAttackModalComponent({refreshAttacks, button_styling=""}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [allAttacks, setAllAttacks] = useState([]);
  const [allModels, setAllModels] = useState([]);
  const [chosenAttackID, setChosenAttackID] = useState([]);
  const [chosenModelID, setChosenModelID] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("chosenAttack:", chosenAttackID)
    console.log("chosenModel:", chosenModelID)

    const attackName = e.target.elements.attackName.value;
    const volume = e.target.elements.volume.value;
    const modelId = chosenModelID;
    const tmrykId = chosenAttackID;
    
    const createAttackReqBody = {
      "id": -1, // Unused but required by API
      "name": attackName,
      "attack_volume": parseInt(volume),
      "tmryk_id": tmrykId,
      "model_id": modelId,
    }

    client.post("/attack", createAttackReqBody)
    .then(response => {
      toast.success("Attack created successfully!");
      refreshAttacks();
    })
    .catch(error => {
      console.log("Error creating attack: " + error);
    })
    
    handleClose();
  };

  useEffect(() => {
    client.get("/attack_code")
    .then(response => {
      setAllAttacks(response.data);
    })
    .catch(() => console.log("Error getting /attack_code"));

    client.get("/model")
    .then(response => {
      setAllModels(response.data);
    })
    .catch(() => console.log("Error getting /model"))
  }, []);

  return (
    <div>
      <a onClick={handleOpen} className='cursor-pointer'>
      <PlusIcon
        styling = {`${button_styling} ml-12 mb-4 border-4 w-32 rounded-md inline-flex`}
        text="Add Attack" />
      </a>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='flex justify-center mb-4'>
            <h2 id="modal-modal-title" className='text-2xl '>Configure Attack</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <TextField id="outlined-basic" sx={{width:"10rem", marginRight: "3.8rem"}} label="Attack Name" name="attackName" variant="outlined" />
            <TextField id="outlined-basic" sx={{width:"10rem", marginBottom: "1.5rem"}} label="Volume" name="volume" variant="outlined" />
            
            <BasicSelect 
              label="Choose Model"
              content={allModels}
              styling="mb-6"
              onChange={setChosenModelID} />

            <SelectTmrykIDComponent
              label="Choose Attack"
              content={allAttacks}
              styling="mb-6"
              onChange={setChosenAttackID} />

            <div className="flex justify-center">
              <Button type="submit" variant="contained" style={{ backgroundColor: '#e9e9ed', color: '#000', marginRight: '2rem'}}>Submit</Button>
              <Button onClick={handleClose} variant="contained" style={{ backgroundColor: '#e9e9ed', color: '#000' }}>Cancel</Button>
            </div>
          </form>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}