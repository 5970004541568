import React, { useEffect, useState } from 'react';
import client from '../client';
import config from "../config"
import { useParams } from 'react-router-dom';
import LoadingSpinnerComponent from '../components/LoadingSpinnerComponent';
import BasicSelect from '../components/BasicSelectComponent';
import theme from '../theme';

export const metricsReqBody = {
  "sensitive": {
    "race": "race|ethnicity",
    "sex": "sex|gender|male|female",
    "age": "age|Age|years?_old|birthdate|birth_year",
    "religion": "religion|faith",
    "disability": "disability|disabled",
    "sexual_orientation": "sexual_orientation|sexuality",
    "national_origin": "nationality|origin|citizenship",
    "marital_status": "marital_status|relationship_status",
    "region": "region|location|area|city|state|country",
    "zip_code": "zip(_)?code|postal_code|postcode|pincode"
  }
}

function ModelChartPage() {
    const { id } = useParams(); 
    const imgDownloadBaseUrl = config["API_BASE_URL"] + "/download"

    const [images, setImages] = useState([]);

    const [allModels, setAllModels] = useState([]);
    const [currentModel, setCurrentModel] = useState(null);

    
    const handleChange = (selectedModel) => {    
      window.location.href = `/model/${selectedModel}`;
    };

    useEffect(() => {
      client.get("/model")
        .then(response => {
          setAllModels(response.data);
          setCurrentModel(response.data.find((model) => model.id === id));
          // Return the response to continue the promise chain
          return response.data.find((model) => model.id === id);
        })
        .then((specificModel) => {
          return client.get(`/metrix/${id}`)
          .then((response) => {
            if (response.data.hasOwnProperty("error")) {
              return client.post(`/metrics/${id}`, metricsReqBody)
              .then((response) => response.data.image_path)
              .then((paths) => paths.filter((path) => path.endsWith(".png")))
              .then((imagePaths) => imagePaths.map((imgPath) => imgDownloadBaseUrl + "?file_name=" + encodeURIComponent(imgPath)))
            }
            else {
              var imagesHash = response.data.performance;
              delete imagesHash["report"];
              return Object.values(imagesHash);
            }
          })
        })
        .then((imgDownloadUrls) => setImages(imgDownloadUrls))
        .catch((err) => console.log(err));
    }, [id, imgDownloadBaseUrl]); // Add id to the dependency array

    return ( 
      <div className='content'>
        <div className={`flex pt-8 ml-24 mx-auto ${theme.primary_text_color}`}>
          <p className='text-3xl'>{currentModel ? currentModel.name : ""}</p>
        </div>
        <BasicSelect 
          label={currentModel ? currentModel.name : "Choose Model"}
          content={allModels}
          styling="w-96 ml-24 mt-4"
          onChange={handleChange} />
        {images.length !== 0 ?
          <div className='flex-wrap flex gap-5 pt-8 pb-32 mx-auto justify-center'>
            {images.map((image) => (
              <div className='w-[35rem] h-[30rem]'>
                <img className='border-8' src={image} alt='chart_image'></img>
              </div>
            ))}
          </div>
        : 
        <LoadingSpinnerComponent size={500}/>}
      </div>
    );
  }
  export default ModelChartPage;