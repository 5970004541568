import React, { useEffect } from 'react';

const TitleComponent = ({ loggedIn }) => {
  useEffect(() => {
    const pageTitle = loggedIn === 2 ? 'Tumeryk Model Attack Guard' : 'Tumeryk MAG - Log In or Register';
    document.title = pageTitle;
  }, [loggedIn]);

  return null;
};

export default TitleComponent;
