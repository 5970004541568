import React from 'react';
import ButtonComponent from './ButtonComponent';
import tmrykLogo from '../assets/images/tmryk-logo-small.png';
import { useLocation } from 'react-router-dom';
import theme from '../theme';
import LogoutButtonComponent from './LogoutButtonComponent';

function HeaderComponent() {
    const location = useLocation();
    const selectedColor = "border-b-4 border-gray-500";
    const unselectedColor = "";
    const commonStyling = `hover:bg-gray-200 ${theme.primary_text_color}`
    const isSelected = (route) => location.pathname === route;
    return (
        <div className="header h-32 w-full bg-slate-50 inline-flex">
            <img src={tmrykLogo} alt="tmryk logo" className='w-[18rem] h-32'/>
            {/* TODO: Change  /model/2 to /model/:id*/}
            <ButtonComponent classNames={`${commonStyling} ${isSelected('/model/2') ? selectedColor : unselectedColor}`} text="Dashboard" link_to="/model/2"/>
            <ButtonComponent classNames={`${commonStyling} ${isSelected('/models') ? selectedColor : unselectedColor}`} text="AI Model Catalog" link_to="/models"/>
            {/* <ButtonComponent classNames={isSelected('/datasets') ? selectedColor : unselectedColor} text="Datasets" link_to="/datasets"/> */}
            <ButtonComponent classNames={`${commonStyling} ${isSelected('/attacks') ? selectedColor : unselectedColor}`} text="Attacks" link_to="/attacks"/>
            <ButtonComponent classNames={`${commonStyling} ${isSelected('/docs') ? selectedColor : unselectedColor}`} text="Docs" link_to="/docs"/>
            {window.location.pathname !== '/' && (
                <div className="ml-auto mr-2 my-auto">
                    <LogoutButtonComponent classNames={`${commonStyling} bg-green-100 text-slate-700 rounded-3xl ${isSelected('/') ? selectedColor : unselectedColor}`} text="Logout" link_to="/"/>
                </div>
            )}
        </div>
    );
  }
  
  export default HeaderComponent;