import React from 'react';

function PlusIcon({styling, text}) {
  return (
    <div className={styling}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg> 
      <p className='ml-2'>{text}</p>
    </div>
  );
}
  
export default PlusIcon;