import ModelsTableComponent from "../components/ModelsTableComponent";
import { useEffect, useState } from "react";
import client from "../client";

function ModelsPage() {

  const [modelsData, setModelsData] = useState(-1);

  useEffect(() => {
    client.get("/model")
    .then(response => setModelsData(response.data))
    .catch(() => console.log("error on modelsPage"))
  }, []);
  
  return (
    <div className="content">
      <div className="mt-10">
        <ModelsTableComponent data={modelsData}/>
      </div>
    </div>
  );
}
  
  export default ModelsPage;