import React from 'react';
import ModalComponent from "../components/ModalComponent";
import LoadingSpinnerComponent from './LoadingSpinnerComponent';
import theme from '../theme';
import PDFIcon from '../assets/PDFIcon';
import client from "../client";

function ModelsTableComponent({data}) {
  const rowStyling = `p-2 border-2 ${theme.primary_text_color}`
  
  if (data !== -1) {
    data.sort((a, b) => (a.id > b.id) ? 1 : -1);
  }

  const getPDFforModel = (modelId) => {
    return () => {
      client.get('/metrix/' + modelId)
      .then(response => {
        window.open(response.data["performance"]["report"], '_blank');
      })
      .catch(error => console.log(error))
    }
  }

  return ( data === -1 ?
    <LoadingSpinnerComponent size={300}/>
    : 
    ( data.length > 0 ?
    <div>
    <ModalComponent />
    <table className="table-auto ml-12 mb-16">
    
      <thead>
        <tr>
          <th className={rowStyling}>ID</th>
          <th className={rowStyling}>Name</th>
          <th className={rowStyling}>Last Updated</th>
          <th className={rowStyling}>Description</th>
          <th className={rowStyling}>Type</th>
          {/* <th className={rowStyling}>Dataset</th> */}
          <th className={rowStyling}>Metrics</th>
          <th className={rowStyling}>PDF</th>
        </tr>
      </thead>

      <tbody>
      {data.map((item, index) => (
        <tr key={index} className={index % 2 === 0 ? 'bg-green-50' : 'bg-gray-50'}>
          <td className={rowStyling}>{item.id}</td>
          <td className={rowStyling}>{item.name}</td>
          <td className={rowStyling}>{item.date_posted}</td>
          <td className={rowStyling}>{item.description}</td>
          <td className={rowStyling}>{item.model_type}</td>
          {/* <td className={rowStyling}>{item.dataset}</td> */}
          <td className={`${rowStyling} flex items-center justify-center`}>
            
          { item.performance !== null ?
            (<a href={`/model/${item.id}`} className='cursor-pointer hover:text-sky-500' >
              Click Here
            </a>)
            :
            (<div className='cursor-default text-gray-500 ' >
              Please invoke attack
            </div>)
          }
          </td>
          <td className={rowStyling}><PDFIcon styling={'cursor-pointer'} onClickFunc={getPDFforModel(item.id)}/></td>
        </tr>
      ))}
      </tbody>

    </table>
  </div>
  :
    (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-bold">No Models Found</h1>
        <p className="text-lg">Please create a model to see it here</p>
        <ModalComponent button_styling="!ml-0 !mt-4"/>
      </div>
    )
  )
  );
}
  
  export default ModelsTableComponent;