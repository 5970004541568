// TMRYK
import tmrykLogo from '../assets/images/tmryk-logo-small.png';
import config from "../config"

// MUI
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Other
import client from '../client';
import CONSTANTS from '../constants';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Register() {

  // Check if the checkbox is checked
  const [checked, setChecked] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let reqBody = {
      "id": -1, // Unused but required
      "email": data.get('email'),
      "username": data.get('username'),
      "hashed_password": data.get('password')
    }

    // Make a request to /portal/user with the request body
    client.post("/portal/user", reqBody)
    .then(response => {
      console.log(response);
      let reqBody = {
        "id": -1,
        "name": "tenant_" + response.data.username,
        "description": "Tenant created automatically for user " + response.data.username,
        "account_id": -1,
        "is_active": true,
        "users": { // maps response.username to response.id
          "username1": `${response.data.username}`
        }
      };
      client.post("/tenant", reqBody)
      .then(response => {
        console.log(response);
        window.location.href = '/login';
      })
      .catch(error => {
        console.log(error);
      });
    })
    .catch(error => {
      console.log(error);
    });

  };

  return (
    <div>
      <div>
        <img src={tmrykLogo} alt="tmryk logo" className='w-[32rem] h-48 mx-auto mt-10'/>
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Create an Account
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Checkbox for user agreement */}
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={
                  <Typography>
                    I agree to the <Link href={CONSTANTS['saas_user_agreement']} target="_blank" rel="noopener noreferrer">SaaS User Agreement</Link>
                  </Typography>
                }
                onChange={(event) => setChecked(event.target.checked)}
              />
              {/* Disabled button if email, username, or password is empty, or if checkbox is not checked */}
              <Button
                disabled={!(checked) || 
                  !document.getElementById('username').value || 
                  !document.getElementById('password').value ||
                  !document.getElementById('email').value
                }
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container className='justify-center'>
                <Grid item>
                  <Link href="/login" variant="body2">
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}